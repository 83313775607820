<template>
  <div class="product">
    <Header activeTit="产品中心"></Header>
    <div class="banner">
      <img src="./../assets/img/banner.png" alt="">
    </div>
    <div class="content">
      <div class="title">产品展示</div>
      <div class="blue_line">
        <div class="box"></div>
        <div class="line"></div>
        <div class="box"></div>
      </div>
      <div class="wrap">
        <div :class="[activeTitle === v.title ? 'active' : '', 'p_box']" v-for="(v, k) in contents" :key="k" @click="changePage(v.title)">
          <img class="p_img" :src="require('./../assets/img/' + v.src)" alt="">
          <div class="p_tit">{{v.title}}</div>
        </div>
      </div>
      <div class="detail" v-for="(v, k) in contents" :key="k">
        <div v-if="activeTitle === v.title">
          <div class="tit">产品概述</div>
          <div class="text">{{v.desc}}</div>
          <div class="tit">功能特点</div>
          <div class="text">{{v.character}}</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './layout/Header'
import Footer from './layout/Footer'
export default {
  name: 'product',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      contents: [
        {
          title: '医疗版脉诊仪',
          src: '脉诊仪.jpg',
          desc: '通过本设备的客观采集分析，为临床医疗提供精确、客观的数据参数，可用于面对面诊疗(建电子病历，以便进行客观的记录，并可以追溯、可以对照)、远程医疗(互联网医院)、社区医院(治未病中心的必备设备)等。',
          character: '专业化程度高、参数完整'
        },
        {
          title: '社区版脉诊仪',
          src: '社区版.jpg',
          desc: '基于全新点阵传感器的小型设备，可以通过手机或PAD与设备连接，实现在专属APP上直接操控设备，并获取检测报告。',
          character: '造价低，操作简单，具有慢病治疗方案指导(糖尿病、高血压、肥胖、失眠等)'
        },
        {
          title: '四诊仪',
          src: '四诊仪.png',
          desc: '将望诊、脉诊、体质辨识合为一体，包括舌面模块、体质辨识模块、脉诊模块、慢病管理模块等。',
          character: '多诊合参技术；采用人工智能进行舌面判断；提供与医院his系统对接服务'
        }
      ],
      activeTitle: '医疗版脉诊仪'
    }
  },
  methods: {
    changePage (item) {
      this.activeTitle = item
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../assets/css/basic.styl'
.product
  .banner
    & img
      width 100%
      height 5.65rem
  .content
    width 13.8rem
    margin 0.86rem auto 1.25rem
    text-align center
    .title
      font-size 0.32rem
      letter-spacing 0.2rem
      font-weight bold
    .blue_line
      width 0.9rem
      height 0.07rem
      margin 0.46rem auto 0.75rem
      display flex
      .box
        width 0.05rem
        background #004ea2
      .box:nth-child(1)
        margin-right 0.15rem
      .line
        width 0.5rem
        background #004ea2
        margin-right 0.15rem
    .wrap
      display flex
      justify-content space-between
      .p_box
        height 4rem
        margin 0.5rem 0 1rem 0
        .p_img
          width 3rem
          height 3.4rem
        .p_tit
          font-size 0.28rem
          text-align center
          line-height 0.7rem
          margin-top -0.1rem
      .p_box:hover, .active
        color #004ea2
        cursor pointer
        .p_img
          border 0.01rem solid #004ea2
        .p_tit
          background #004ea2
          color #fff
    .detail
      font-size 0.2rem
      text-align left
      line-height 0.5rem
      .tit
        font-size 0.26rem
        color #004ea2
        border-bottom 0.01rem dotted #004ea2
        margin-bottom 0.1rem
      .text
        margin-bottom 0.5rem
</style>
